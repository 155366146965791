import { defaultNs } from 'lib/i18n/defaults';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Error from 'next/error'

export default function NotFound(props) {
    return <Error statusCode={404} {...props} />
}

export async function getStaticProps({ locale }) {
    return {
        props: {
            ...await serverSideTranslations(locale, [...defaultNs]),
        }
    };
}